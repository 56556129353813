import { all, call, fork, put, select, takeEvery, delay } from 'redux-saga/effects';
import { RootState } from '../..';
import { BOOKING_DETAIL_GENERAR_NOTA_CREDITO, BOOKING_DETAIL_SET_SELECT } from "./actionsTypes";
import { StateBookingDetail, BookingDetail as IBookingDetail } from '../../../interfaces/detailBooking';
import { get, post, put as putBD } from '../../../helpers/api_helper';
import { setComments, setComponents, setIsNotaCredito, setPax, setPickUpDropOffs } from './actions';
import { notifications } from '../../../helpers/notifications';
import { msalInstance } from "../../../index";
import { dateTimeToDateForms } from "../../../helpers/utilsDate";
import { Booking as IBooking, BookingsState, CambioEstadoBooking } from "../../../interfaces/booking";
import { setCurrentBooking, setModalNotaCredito } from "../booking/actions";
import { StateCatalogos } from "../../catalogos/CatalogosReducer";
import { Estado } from '../../../interfaces/estado';

const BookingDetail = (state: RootState) => state.BookingDetail;
const Booking = (state: RootState) => state.Booking;
const Catalogos = (state: RootState) => state.Catalogos;

const getUserByEmail = (email: string) => {
    return get(`Usuarios?$filter=NombreUsuario eq '${email}'`)
        .then(res => {
            if (res.value.length === 0) {
                return { UsuarioId: 0 };
            } else {
                return res.value[0];
            }
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

const getComenByID = (id: number) => {
    return get(`/BookingDetails?$select=BookingDetailId&$expand=Comentario($expand=TipoComentario($select=Nombre))&$filter=BookingDetailId eq ${id}`)
        .then(comment => {
            if (comment.value[0].Comentario.length === 0) {
                return [];
            } else {
                return comment.value[0].Comentario;
            }
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

const getPaxByID = (id: number) => {
    return get(`/BookingDetails?$select=BookingDetailId&$expand=AsignacionPasajero($expand=Pasajero($expand=TipoPasajero($select=Valor)))&$filter=BookingDetailId eq ${id}`)
        .then(paxs => {
            if (paxs.value[0].AsignacionPasajero.length === 0) {
                return [];
            } else {
                return paxs.value[0].AsignacionPasajero.sort((a: { Pasajero: { NumeroPasajero: number; }; }, b: {
                    Pasajero: { NumeroPasajero: number; };
                }) => {
                    return a.Pasajero.NumeroPasajero - b.Pasajero.NumeroPasajero;
                });
            }
        }).catch(error => {
            notifications({ ex: error });
        });
}

const getPickUpDropByID = (id: number) => {
    return get(`/BookingDetails?$select=BookingDetailId&$expand=PickupDropoff($expand=Vuelo($select=Numero))&$filter=BookingDetailId eq ${id}`)
        .then(pickupDrop => {
            if (pickupDrop.value[0].PickUpDropOff.legth === 0) {
                return [];
            } else {
                return pickupDrop.value[0].PickUpDropOff;
            }
        }).catch(error => {
            notifications({ ex: error });
        })
}

const getComponent = (pleId: number) => {
    return get(`EstructuraProductosCompuesto?$expand=Componente($expand=Estacion)&filter=ProductoEntidadLegalId eq ${pleId}`)
        .then(legoland => {
            if (legoland.value.length === 0) {
                return [];
            } else {
                return legoland.value;
            }
        })
        .catch(error => {
            notifications({ ex: error });
        })
}

function* getPickupByID(id: number): any {
    const pickUpDrop = yield call(getPickUpDropByID, id);
    yield put(setPickUpDropOffs(pickUpDrop));
}

function* getComentariosByBD_Id(id: number): any {
    const comment = yield call(getComenByID, id);
    yield put(setComments(comment));
}

function* getPasajerosAsignadoByBD_Id(id: number): any {
    const paxs = yield call(getPaxByID, id);
    yield put(setPax(paxs));
}

function* getComponentsLegolandByPLEId(pleId: number): any {
    const components = yield call(getComponent, pleId);
    yield put(setComponents(components));
}

function* getPickUpDropOffByBD_Id(id: number): any {
    yield call(getPickupByID, id);
}


function* actualizarBDProductosNotaCredito(productosNotaCredito: IBookingDetail[], email: string): any {
    let newProductosNotaCredito = [...productosNotaCredito];
    const usuarioId = yield call(getUserByEmail, String(email));
    for (let i of newProductosNotaCredito) {
        i.RequiereNotaCredito = true;
        i.FechaNotaCredito = dateTimeToDateForms(new Date());
        i.FechaInicio = dateTimeToDateForms(i.FechaInicio);
        i.FechaFin = dateTimeToDateForms(i.FechaFin);
        i.UsuarioIdCreacionNotaCredito = usuarioId.UsuarioId;
        delete i.selected;
        delete i.inEdit;
        yield call(putBD, `BookingDetails(${i.BookingDetailId})`, i);
    }
}

function* enviarJde(booking: IBooking, estadoCerrado: Estado, productosNotaCredito: IBookingDetail[]): any {
    yield put(setIsNotaCredito(false));
    let estadoAntiguo = booking.EstadoId;
    let bookingNotaCredito = { ...booking };
    bookingNotaCredito.EstadoId = estadoCerrado.EstadoId;
    bookingNotaCredito.Estado = estadoCerrado;

    let newCambioEstadoNotaCredito: CambioEstadoBooking = {
        BookingId: bookingNotaCredito.BookingId,
        EstadoAntiguoId: estadoAntiguo,
        EstadoNuevoId: estadoCerrado.EstadoId,
        FechaCambio: new Date(),
    };

    try {
        yield put(setModalNotaCredito(true));
        yield call(post, `GenerarOrdenesFacturacion(${bookingNotaCredito.BookingId},NotaCredito,${productosNotaCredito.length})`, '');
        yield call(post, `CambiosEstadoBooking`, newCambioEstadoNotaCredito);
        yield put(setCurrentBooking(bookingNotaCredito));
        yield put(setModalNotaCredito(false));
        notifications({
            title: `Operación exitosa`,
            message: `Se ha generado la nota de crédito para el booking con código: ${bookingNotaCredito.BookingCode}`,
            toastType: 'success',
            seRegistra: true,
        });
    } catch (error) {
        notifications({ ex: error });
        yield put(setModalNotaCredito(false));
    }
}

function* getComplementos() {
    const { detailSelected }: StateBookingDetail = yield select(BookingDetail);
    const { BookingDetailId, ProductoEntidadLegalId } = detailSelected;
    yield fork(getComentariosByBD_Id, BookingDetailId);
    yield fork(getPasajerosAsignadoByBD_Id, BookingDetailId);
    yield fork(getPickUpDropOffByBD_Id, BookingDetailId);
    yield fork(getComponentsLegolandByPLEId, ProductoEntidadLegalId);
}

function* generarNotaCredito() {
    const { productosNotaCredito }: StateBookingDetail = yield select(BookingDetail);
    const { selectedBooking }: BookingsState = yield select(Booking);
    const { estados }: StateCatalogos = yield select(Catalogos);
    const estadoNotaCredito = estados["Booking"]["Enviado nota de crédito"];
    const account = msalInstance.getActiveAccount();
    const email = account!.username;
    yield fork(actualizarBDProductosNotaCredito, productosNotaCredito, email);
    yield delay(4000);
    yield fork(enviarJde, selectedBooking!, estadoNotaCredito, productosNotaCredito);
}

function* watchSelectBookingDetail() {
    yield takeEvery(BOOKING_DETAIL_SET_SELECT, getComplementos);
}

function* watchGenerarNotaCredito() {
    yield takeEvery(BOOKING_DETAIL_GENERAR_NOTA_CREDITO, generarNotaCredito);
}

function* bookingDetailSaga() {
    yield all([
        fork(watchSelectBookingDetail),
        fork(watchGenerarNotaCredito),
    ])
}

export default bookingDetailSaga;
